<template>
  <div id="user-view">
    <v-row>
      <v-col
        cols="12"
        md="12"
        lg="12"
      >
        <v-tabs
          v-model="userTab"
          show-arrows
          class="user-tabs"
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.icon"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>
        <v-tabs-items
          id="user-tabs-content"
          v-model="userTab"
          class="mt-5 pa-1"
        >
          <v-tab-item>
            <v-row>
              <v-col
                cols="12"
                md="12"
              >
                <v-card
                  :title="form.aktion == 'editTagCheck' ? 'Tag bearbeiten' : 'Tag erstellen'"
                >
                  <v-card-title v-if="form.aktion == 'editTagCheck'">Tag bearbeiten</v-card-title>
                  <v-card-title v-else>Tag erstellen</v-card-title>

                  <v-card-text>

                    <div class="text-center" v-if="loading">
                      <v-progress-circular
                        :size="50"
                        color="primary"
                        indeterminate
                      ></v-progress-circular>
                    </div>
                    <v-form
                      v-else
                      ref="formRef"
                      @submit.prevent="saveData"
                    >
                      <v-row>
                        <v-col cols="12" md="12">
                          <v-text-field
                            v-model="form.mainData.titel"
                            label="Titel"
                            type="text"
                            outlined
                            dense
                            hide-details="auto"
                            placeholder="Titel"
                          ></v-text-field>
                        </v-col>

                        <v-col cols="12" md="12">
                          <v-textarea
                            v-model="form.mainData.memo"
                            outlined
                            label="Memo"
                            rows="4"
                            hide-details="auto"
                            placeholder="Memo"
                          ></v-textarea>
                        </v-col>
                      </v-row>

                      <v-row>
                        <v-col cols="12" md="12">
                          <router-link :to="'/tag'">
                            <v-btn
                              color="error"
                              class="mx-2"
                            >
                              Zurück
                            </v-btn>
                          </router-link>
                          <v-btn color="primary" type="submit">
                            Speichern
                          </v-btn>
                          <v-btn
                            type="reset"
                            outlined
                            class="mx-2"
                          >
                            Zurücksetzen
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-form>

                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {onMounted, ref, getCurrentInstance} from '@vue/composition-api'
import router from '@/router'
import {
  mdiHomeOutline,
  mdiEmailOutline,
  mdiLockOutline,
  mdiEyeOffOutline,
  mdiEyeOutline
} from '@mdi/js'

import axios from "axios";
import {emailValidator, required} from "@core/utils/validation";

export default {
  components: {

  },
  setup(props) {

    const userTab = ref(null)
    const tabs = ref([
      { icon: mdiHomeOutline, title: 'Übersicht', id:'overview' },
    ])

    const vm = getCurrentInstance().proxy
    vm.$toastr.defaultTimeout = 2000;

    const formRef = ref(null)
    const form = ref({})

    form.value = {
      mainData : {
        idNipTag : router.currentRoute.params.idNipTag,
        titel : '',
        memo : '',
      },
      aktion: (router.currentRoute.params.idNipTag !== undefined ? 'editTagCheck' : 'createTagCheck'),
    };
    const loading = ref(true)

    onMounted(()=>{

      if(form.value.aktion == 'editTagCheck'){
        axios.post('/api/tag/', {
          aktion: 'editTag',
          mainData:{
            idNipTag : router.currentRoute.params.idNipTag,
          }
        })
          .then((response) => {
            if (response.data.valid === true) {
              form.value.mainData = response.data.tag;
            } else {
              response.data.errors.forEach(item=>{
                let suffix = item.errorField != "" ? "("+item.errorField+")" : "";
                vm.$toastr.e(item.errorText, item.errorTitle+suffix);
              })
            }
            loading.value = false;
          })
          .catch((error) => {
            console.log(error)
            loading.value = true;
          })
      }
      else{
        loading.value = false;
      }
    })

    const saveData = () => {
      const isFormValid = formRef.value.validate()
      if(!isFormValid) return
      loading.value = true;

      const fd = new FormData();
      fd.append('aktion',form.value.aktion);
      fd.append('mainData[idNipTag]',form.value.mainData.idNipTag);
      fd.append('mainData[titel]',form.value.mainData.titel);
      fd.append('mainData[memo]',form.value.mainData.memo);


      axios
        .post('/api/tag/',fd)
        .then(response => {
          if(response.data.valid === true){
            router.push('/tag',()=>{
              vm.$toastr.s(response.data.message.text, response.data.message.title);
            })
          }
          else{
            response.data.errors.forEach(item=>{
              let suffix = item.errorField != "" ? "("+item.errorField+")" : "";
              vm.$toastr.e(item.errorText, item.errorTitle+suffix);
            })
          }
          loading.value = false;
        })
        .catch(error => {
          vm.$toastr.e(error,error);
          loading.value = false;
        })
    }

    return {
      userTab,
      tabs,
      form,
      formRef,
      saveData,
      loading,
      icons: {
        mdiHomeOutline,
        mdiEmailOutline,
        mdiLockOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
      validators: {
        required,
        emailValidator,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';

.user-tabs{
  display: none;
}
#user-tabs-content{
  margin:0 !important;
}
</style>
